import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import Header from "../components/Header";
import { BsClouds, BsGlobe, BsGraphUp, BsVr } from "react-icons/bs";
import Footer from "../components/Footer";
import { Helmet } from "react-helmet-async";


const HeaderContainer = styled.div`
    ${tw`
        // bg-violet-950
    `}
`

const ServiceSection = styled.div`
    ${tw`
        flex
        flex-col
        mt-5
        lg:px-[115px]
        h-auto
        mb-10
    `}
`

const ServiceHeader = styled.div`
    ${tw`
        flex
        items-center
        text-center
        justify-center
        font-medium
        lg:text-2xl
        md:text-2xl
        text-xl
        text-slate-700
        font-Raleway
    `}
    span{
        ${tw`
            border-b-4
            p-2
            border-amber-600
        `}
    }
`

const ServiceList = styled.div`
    ${tw`
        grid
        grid-cols-1
        lg:grid-cols-3
        mt-10
        lg:px-[100px]
        px-[40px]
    `}
`
  
const ServiceItem = styled.div`
    ${tw`
        flex
        flex-col
        md:p-2
        p-1
        mt-2
        col-span-1
    `}
`
const ServiceTitle = styled.div`
    ${tw`
        flex
        text-violet-600
        md:text-xl
        text-lg
        items-center
        justify-start
        gap-3
    `}
`
const ServiceDetail = styled.div`
    ${tw`
        flex
        mt-2
        text-slate-600
    `}
`


const Services = () => {
    return(
        <>
            <Helmet>
                <title>DataTown Analytics | Services</title>
                <meta name="description" content="Discover excellence in IT services and cutting-edge digital solutions at DataTown." />
                <link rel="canonical" href="https://datatown.in/services" />
                {/* <meta name="robots" content="index" /> */}
            </Helmet>

            <HeaderContainer>
                <Header col={'#5b21b6'} />
            </HeaderContainer>

            <ServiceSection>
                <ServiceHeader>
                    <span>Our Services</span>
                </ServiceHeader>
                <ServiceList>
                    <ServiceItem >
                        <ServiceTitle>
                            <BsClouds size={30} />
                            <span>SaaS based applications</span>
                        </ServiceTitle>
                        <ServiceDetail>
                            We provide Software as a Service (SaaS) to consumers who wish to capture the essence of software accessibility around the globe via any device over the internet.
                        </ServiceDetail>
                    </ServiceItem>
                    <ServiceItem>
                        <ServiceTitle>
                            <BsGraphUp size={25}/>
                            <span>BI and Data Analytics</span>
                        </ServiceTitle>
                        <ServiceDetail>
                        We believe in nurturing data with productive analysis. As it serves as the foundation stone of better progression and structure. Hence effective analysis is a must-have for invaluable data.
                        </ServiceDetail>
                    </ServiceItem>
                    <ServiceItem>
                        <ServiceTitle>
                            <BsGlobe size={30}/>
                            <span>Web applications</span>
                        </ServiceTitle>
                        <ServiceDetail>
                            We have extensive experience in working with high-load systems, data engineering and solving complex business problems. We help you save money, time, and ultimately improve the efficiency of your enterprise's processes. Our end-to-end DevOps services allow you to easily transform, optimize, and scale your business.
                        </ServiceDetail>
                    </ServiceItem>

                    <ServiceItem>
                        <ServiceTitle>
                            <BsVr size={30}/>
                            <span>AR / VR</span>
                        </ServiceTitle>
                        <ServiceDetail>
                            We are experienced in developing high-end Augmented Reality (AR) & Virtual Reality (VR) solutions. It helps businesses amplify their product/service value proposition through a highly adept team of AR & VR App Development consultants. 
                            {/* Its end-to-end Augmented Reality App Development services & Virtual Reality App Development solutions are geared towards creating a surreal yet immersive experience for customers while helping to differentiate the brand from the competition. */}
                        </ServiceDetail>
                    </ServiceItem>
                </ServiceList>
            </ServiceSection>

            <Footer />
        </>
    )
}

export default Services