import React, { useState } from "react";
import styled from "styled-components";
import tw from "twin.macro";
import {BiStopwatch} from "react-icons/bi"
import {HiLightBulb} from "react-icons/hi"
import {HiUsers} from "react-icons/hi"
import {BsGlobeAsiaAustralia} from "react-icons/bs"
import CountUp from "react-countup";
import ScrollTrigger from "react-scroll-trigger";

const CounterContainer = styled.div`
    ${tw`
        grid
        md:grid-cols-4
        grid-cols-1
        lg:px-[115px]
        md:px-[40px]
        px-[30px]
        lg:py-[125px]
        py-[50px]
        gap-2
    `}
`

const CounterItem = styled.div`
    ${tw`
        flex
        items-start
        justify-start
        gap-2
    `}
`

const CounterLogo = styled.div`
    ${tw`
        flex
        text-pink-500
    `}
`
const CounterText = styled.div`
    ${tw`
        flex
        flex-col
        // text-pink-500
    `}
`
const CounterValue = styled.div`
    ${tw`
        text-4xl
        font-bold
        text-slate-600
    `}
`

const CounterSubText = styled.div`
    ${tw`
        text-base
        pr-5
    `}
`

const CounterSection = () => {
    const [counterOn, setCounterOn] = useState(false)
    return(
        <ScrollTrigger onEnter={() => setCounterOn(true)} onExit={() => setCounterOn(false)}>
            <CounterContainer>
                <CounterItem>
                    <CounterLogo><BiStopwatch size={60} color="" /></CounterLogo>
                    <CounterText>
                        <CounterValue>
                            {
                                counterOn && (
                                    <CountUp start={0} end={3} duration={3} />
                                )
                            }
                        </CounterValue>
                        <CounterSubText>
                            Years of Transforming Businesses
                        </CounterSubText>
                    </CounterText>
                </CounterItem>

                <CounterItem>
                    <CounterLogo><HiLightBulb size={60} color="" /></CounterLogo>
                    <CounterText>
                        <CounterValue>
                            {
                                counterOn && (
                                    <CountUp start={0} end={176} duration={3} />
                                )
                            }
                        </CounterValue>
                        <CounterSubText>
                            Successfull Projects
                        </CounterSubText>
                    </CounterText>
                </CounterItem>

                <CounterItem>
                    <CounterLogo><HiUsers size={60} color="" /></CounterLogo>
                    <CounterText>
                        <CounterValue>
                            {
                                counterOn && (
                                    <CountUp start={0} end={30} duration={3} />
                                )
                            }
                        </CounterValue>
                        <CounterSubText>
                            Happy Customers Acroos the Globe
                        </CounterSubText>
                    </CounterText>
                </CounterItem>

                <CounterItem>
                    <CounterLogo><BsGlobeAsiaAustralia size={55} color="" /></CounterLogo>
                    <CounterText>
                        <CounterValue>
                            {
                               counterOn && (
                                   <CountUp start={0} end={2} duration={3} />
                               ) 
                            }
                        </CounterValue>
                        <CounterSubText>
                            Global Presence
                        </CounterSubText>
                    </CounterText>
                </CounterItem>
            </CounterContainer>
        </ScrollTrigger>
    )
}

export default CounterSection