import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import {GiSupersonicArrow} from "react-icons/gi"

const JourneyContainer = styled.div`
    ${tw`
        flex
        flex-col
        w-full
        lg:px-[115px]
        md:px-[40px]
        px-[30px]
        bg-pink-500
        py-8

    `}
`

const JourneyHeader = styled.div`
    ${tw`
        w-full
        p-2
        md:text-2xl
        text-xl
        text-white
        font-medium
        flex
        flex-col
        lg:justify-center
        md:justify-center
        md:mt-5
    `}
`

const JourneyText = styled.div`
    ${tw`
        flex
        mt-5
        text-white
        font-Raleway
        text-center
        items-center
        justify-center
    `}
`

const JourneySection = () => {
    return(
        <JourneyContainer>
            <JourneyHeader>
                <div className="flex text-center items-center justify-center">
                    <h3>Join Us on the Journey</h3>
                </div>
                <div className="flex text-center items-center justify-center -rotate-[90deg] mt-5">
                    <span><GiSupersonicArrow size={40} /> </span>
                </div>
            </JourneyHeader>
            <JourneyText>
                <p>Whether you're a business seeking to transform through technology or an individual with a passion for innovation, DataTown Analytics invites you to join us on this exciting journey. Together, we'll navigate the digital landscape, harness the power of data, and build a future where technology empowers and inspires.</p>
            </JourneyText>           
        </JourneyContainer>
    )
}

export default JourneySection