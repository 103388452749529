import React from "react";
import styled from "styled-components";
import tw from "twin.macro";

const AboutContainer = styled.section`
    ${tw`
        // bg-slate-200
        // lg:mt-[calc(100vh - 96px)]
        // mt-[calc(100vh - 90px)]
        // bg-amber-600
        w-full
        lg:px-[120px]
        md:px-[120px]
        px-[30px]
        py-5
        grid
        lg:grid-cols-2
        // grid-cols-1
        gap-3
    `}
`

const AboutHeader = styled.div`
    ${tw`
        w-full
        py-2
        md:text-3xl
        text-xl
        text-slate-700
        font-bold
        flex
        flex-col
        justify-center
        md:mt-5
        col-span-2
    `}
    span{
        ${tw`
            md:text-3xl
            tracking-wider
            text-3xl
            text-violet-700
            font-bold
            font-Alfa
        `}
    }
    h1{
        ${tw`
            md:text-4xl
            text-4xl
            tracking-wider
            font-Alfa
            text-slate-600
            font-bold
        `}
    }
`

const AboutCompany = styled.div`
    ${tw`
        py-5
        lg:px-10
        
    `}
    h2{
        ${tw`
            text-violet-800
            text-xl
            mb-2
        `}
    }
    p{
        ${tw`
            
        `}
    }
`

const AboutSection = () => {
    return(
        <AboutContainer id="about">
            {/* <div className="col-span-1 md:col-span-2 flex w-full text-center justify-center items-center text-2xl font-medium text-slate-600">
                About Us
            </div> */}
            <div className=" flex text-center items-center justify-center">
                <AboutHeader>
                    <span>We Are</span>
                    <h1>DataTown</h1>
                </AboutHeader>
            </div>
            <div className="">
                <AboutCompany >
                    {/* <h3>Our History</h3> */}
                    <p>Welcome to DataTown, a pioneering IT services and digital product development company, established in 2021 by visionary founder Danish M and Alok Gupta </p>
                    <br></br>
                    <p>DataTown  was founded on the principle of harnessing the power of data and technology to drive innovation and transformation. Since our inception, we have been on an unwavering journey to leverage data-driven insights and cutting-edge technology to empower businesses and individuals alike.</p>
                    <br></br>
                    <h2>Our Mission</h2>
                    <p>At DataTown, our mission is to empower organizations and individuals with the tools, insights, and solutions they need to thrive in the digital age. We are dedicated to helping our clients navigate the complexities of the ever-changing tech landscape, making technology work for them and not against them.</p>
                </AboutCompany>
            </div>
            {/* <CompanyContainer>
                

                <AboutCompany className="col-span-1 md:col-span-2 lg:col-span-1">
                    <h3>Our Expertise</h3>
                    <p>With a talented team of IT professionals, data scientists, engineers, and creatives, we bring a wealth of knowledge and experience to every project. Our expertise spans a wide range of domains, including data analytics, artificial intelligence, cloud computing, software development, and more. This diverse skill set allows us to craft innovative and tailor-made solutions for our clients' unique challenges.</p>
                    <br></br>
                    <h3>Our Commitment to Clients</h3>
                    <p>Our clients are at the heart of everything we do. We forge strong partnerships, working closely with each client to understand their goals and challenges. We then apply our technical expertise and creative thinking to deliver solutions that exceed expectations.</p>
                </AboutCompany>

                <AboutCompany className="col-span-1 md:col-span-2">
                    <h3>Join Us on the Journey</h3>
                    <p>Whether you're a business seeking to transform through technology or an individual with a passion for innovation, DataTown Analytics invites you to join us on this exciting journey. Together, we'll navigate the digital landscape, harness the power of data, and build a future where technology empowers and inspires.</p>
                </AboutCompany>

                <AboutCompany className="col-span-1 md:col-span-2 ">
                    <p>Thank you for considering DataTown as your trusted partner in IT services and digital innovation. We look forward to working with you to turn your visions into reality and achieve your digital ambitions.</p>
                </AboutCompany>
            </CompanyContainer> */}
            
        </AboutContainer>
    )
}

export default AboutSection