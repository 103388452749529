import {BrowserRouter, Routes, Route} from "react-router-dom"
import TagManager from "react-gtm-module";
import Home from "./pages/Home";
import Products from "./pages/Products";
import Services from "./pages/Services";
import Career from "./pages/Career";
import Page404 from "./pages/404";
import { Helmet } from "react-helmet-async";
import Carousel from "./components/Carousel";
// import PrivacyPolicy from "./pages/PrivacyPolicy";

const tagManagerArgs = {
  gtmId: 'GTM-PLZ3FSG5'
}

TagManager.initialize(tagManagerArgs)

function App() {
  window.dataLayer.push({
    event: 'pageview'
});

  return (
    <div>
      <Helmet>
        <title>DataTown | Transforming World digitally with Our Technology </title>
        <link rel="canonical" href="https://datatown.in/" />
        <meta name="description" content="Discover excellence in IT services and cutting-edge digital solutions at DataTown."></meta>
        <meta property="og:type" content="website"></meta>
        <meta property="og:title" content="DataTown | Transforming World digitally with Our Technology"></meta>
        <meta property="og:description" content="Discover excellence in IT services and cutting-edge digital solutions at DataTown."></meta>
        <meta property="og:url" content="https://datatown.in/"></meta>
        <meta property="og:site_name" content="Datatown"></meta>
        <meta property="og:image" content="https://datatown.in/uploads/OG-Datatown.png" />
        {/* <meta name="keywords" content="IT services, innovative digital products, AR/VR solutions, SEO services, mobile development, web development, business intelligence, data analytics, technology solutions, IT consulting, software development, digital transformation, technology experts, IT solutions provider, school management system, hosptial management system, learning management system"/> */}
      </Helmet>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/products" element={<Products/>} />
          <Route path="/services" element={<Services/>} />
          <Route path="/careers" element={<Career/>} />
          <Route path="/*" element={<Page404 />} />
          <Route path="/test" element={<Carousel />} />
          {/* <Route path="/privacy-policy" element={<PrivacyPolicy/>} /> */}
        </Routes>  
      </BrowserRouter>
    </div>
  );
}

export default App;
