import React from "react";
import ContactUs from "../components/ContactUs";
import Footer from "../components/Footer";
import ServiceSection from "../components/ServiceSection";
import AboutSection from "../components/AboutSection";
import { Helmet } from "react-helmet-async";
import Carousel from "../components/Carousel";
import CounterSection from "../components/CounterSection";
import JourneySection from "../components/JourneySection";

const Home = () => {

    return(
        <>
            <Helmet>
                <title>DataTown | Transforming World digitally with Our Technology</title>
                <meta name="description" content="Discover excellence in IT services and cutting-edge digital solutions at DataTown." />
                <link rel="canonical" href="https://datatown.in/" />
                {/* <meta name="robots" content="index" /> */}
                <meta property="og:image" content="https://datatown.in/uploads/OG-Datatown.png" />
                <meta property="og:image:width" content="1200" />
                <meta property="og:image:height" content="630" />
                <meta property="og:image:type" content="image/png"/>
                <meta property="og:type" content="website"></meta>
                <meta property="og:title" content="DataTown | Transforming World digitally with Our Technology"></meta>
                <meta property="og:description" content="Discover excellence in IT services and cutting-edge digital solutions at DataTown."></meta>
                <meta property="og:url" content="https://datatown.in/"></meta>
                <meta property="og:site_name" content="Datatown"></meta>
                
                {/* Yoast */}
            </Helmet>
            <Carousel/>
            <AboutSection />
            <ServiceSection/>
            <CounterSection/>
            <JourneySection />
            <ContactUs/>
            <Footer/>
        </>
    )
}

export default Home