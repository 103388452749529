import React from "react";
import Header from "../components/Header";
import styled from "styled-components";
import tw from "twin.macro";
import Footer from "../components/Footer";
import { Helmet } from "react-helmet-async";

const HeaderContainer = styled.div`
    ${tw`
        // bg-violet-950
    `}
`

const ProductSection = styled.div`
    ${tw`
        flex
        flex-col
        mt-5
        lg:px-[115px]
        h-[475px]
    `}
`

const ProductHeader = styled.div`
    ${tw`
        flex
        items-center
        text-center
        justify-center
        font-medium
        lg:text-2xl
        md:text-2xl
        text-xl
        text-slate-700
        font-Raleway
    `}
    span{
        ${tw`
            border-b-4
            p-2
            border-amber-600
        `}
    }
`

const ProductList = styled.div`
    ${tw`
        grid
        grid-cols-1
        mt-10
        lg:px-[100px]
        px-[40px]
    `}
`

const ProductItem = styled.div`
    ${tw`
        flex
        flex-col
        md:flex-row
        col-span-1
        border
        border-gray-200
        mb-5
        rounded-sm
    `}
`
const ProductLogo = styled.div`
    ${tw`
        flex
        text-center
        items-center
        justify-center
        bg-gray-100
        md:w-[40%]
        w-full
        text-3xl
        font-Raleway
        font-bold
        py-5
        md:py-2
    `}
`

const ProductInfo =styled.div`
    ${tw`
        flex
        flex-col
        w-full
    `}
`

const ProductTitle = styled.div`
    ${tw`
        p-2
        md:text-xl
        text-sm
        font-Raleway
        font-medium

    `}
`

const ProductDetails  = styled.div`
    ${tw`
        p-2
        text-slate-700
        font-medium
        md:text-base
        text-sm
        font-Raleway
    `}
    ul{
        ${tw`
            list-disc
            ml-10
        `}
    }
`

const Products = () => {
    return(
        <>
            <Helmet>
                <title>DataTown Analytics | Products</title>
                <meta name="description" content="Discover excellence in IT services and cutting-edge digital solutions at DataTown." />
                <link rel="canonical" href="https://datatown.in/products" />
                {/* <meta name="robots" content="index" /> */}
            </Helmet>

            <HeaderContainer>
                <Header col={"#5b21b6"}/>
            </HeaderContainer>

            <ProductSection>
                <ProductHeader>
                    <span>Our Products</span>
                </ProductHeader>
                <ProductList>
                    <ProductItem>
                        <ProductLogo>
                            GSERP
                        </ProductLogo>
                        <ProductInfo>
                            <ProductTitle>
                                Global School Management Suite
                            </ProductTitle>
                            <ProductDetails>
                                {/* <h4>Features:</h4> */}
                                <ul>
                                    <li>Cloud Based Solution</li>
                                    <li>Lightening Fast</li>
                                    <li>Highly Secured</li>
                                    <li>Personalized Portals for Students, Teachers and Parents</li>
                                    <li>Huge library of learning resources</li>
                                    <li>Integrated payment gateways</li>
                                </ul>
                            </ProductDetails>
                        </ProductInfo>
                    </ProductItem>
                    {/* <ProductItem>1</ProductItem>
                    <ProductItem>1</ProductItem>
                    <ProductItem>1</ProductItem> */}
                </ProductList>
            </ProductSection>
            <Footer/>
        </>
    )
}

export default Products