import { collection } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import tw from "twin.macro";
import { addQuery } from "../firebase/utility";



const ContactUsContainer = styled.div`
    ${tw`
        bg-violet-900
        // lg:mt-[calc(100vh - 96px)]
        // mt-[calc(100vh - 90px)]
        w-full
        lg:px-[120px]
        md:px-[120px]
        px-[30px]
        py-5
        grid
        lg:grid-cols-2
        grid-cols-1
        gap-3
    `}
`

const ContactHeader = styled.div`
    ${tw`
        w-full
        py-3
        md:text-2xl
        text-xl
        text-white
        flex
        lg:justify-start
        md:justify-center
        md:mt-5
    `}
`
const ContactForm = styled.div`
    ${tw`
        w-full
        mt-5
        grid
        grid-cols-1
        md:grid-cols-2
        gap-2
    `}
`

const FormField = styled.div`
    ${tw`
        flex
        flex-col
        text-white
        mb-1
        
    `}
    input, select{
        ${tw`
            outline-none
            py-2
            bg-transparent
            border-b
            
        `}
    }
`

const CheckField = styled.div`
    ${tw`
        flex
        text-white
        mb-1
        mt-1
        gap-3
        items-center
    `}
    input{
        ${tw`
            h-4
            w-4        
        `}
    }

    a{
        ${tw`
            underline
        `}
    }
`

const FileField = styled.div`
    ${tw`
        flex
        text-white
        mb-1
        mt-1
        gap-3
        items-center
        relative
    `}
    input{
        ${tw`
            outline-none
            py-2
            bg-transparent
            border-b
            w-full
        `}
    }
`
const ButtonContainer = styled.div`
    ${tw`
        flex
        mt-8
    `}
    button{
        ${tw`
            bg-amber-600
            px-10
            py-2
            text-white
            rounded-lg
            uppercase
            font-Raleway
        `}
    }
`

const FileInstructions = styled.div`
    ${tw`
        flex
        absolute
        items-end
        justify-end
        text-right
        right-2
    `}
`
const FileFieldSize = styled.div`
    ${tw`
        font-medium
        text-sm
        md:flex
        hidden
    `}
`

const FormContainer = styled.div`
    ${tw`
        col-span-1
        order-2
        lg:order-1
    `}
`
const ContactQuote = styled.div`
    ${tw`
        col-span-1
        hidden
        md:flex
        md:flex-col
        lg:px-[100px]
        md:px-[50px]
        items-center
        text-center
        justify-center
        text-violet-100
        font-bold
        lg:text-4xl
        md:text-3xl
        text-xl
        order-1
        lg:order-2
        border-b-2
        border-dashed
        border-white
        lg:border-0
        pb-10
    `}
    span{
        ${tw`
            text-amber-600
        `}
    }
`

const SubQuote = styled.div`
    ${tw`
        flex
        text-base
        mt-10
        text-gray-200
        font-normal
    `}
`

const ContactUs = () => {
    const [formData, setFormData] = useState(null)
    const [qryMsg, setQueryMsg] = useState(null)
    const [qryStatus, setQueryStutus] = useState(null)

    const handleInputChange = (e) => {
        const name = e.target.name
        const value = e.target.value

        setFormData({
            ...formData,
            [name]: value
        })
    }

    const handleCheckChange = (e) => {
        const name = e.target.name
        const value = e.target.checked

        setFormData({
            ...formData,
            [name]: e.target.checked ? true : false
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        // console.log("Submitted Data", formData)
        addQuery(formData)
            .then((val) => {
                // console.log("Returned value", val)
                setQueryMsg("Your query has been submitted, we will get back to you shortly")
                setQueryStutus(true)
            })
            .catch((err)=> {
                // console.log(err)
                setQueryMsg("Something went wrong, could not submit your query")
                setQueryStutus(false)
            })
    }

    useEffect(()=>{
        if(qryStatus){
            setTimeout(()=>{
                setQueryMsg(null)
            },10000)
        }
    },[qryMsg, qryStatus])
    
    
    return(
        <ContactUsContainer id="contactUs">
            <FormContainer>
                <ContactHeader>
                    <span>We’d love to hear from you</span>
                </ContactHeader>
                <form onSubmit={handleSubmit}>
                    <ContactForm>
                        <FormField className="col-span-1 md:col-span-2">
                            <label>Your name</label>
                            <input 
                                type="text" 
                                name="prospect" 
                                placeholder="John Smith" 
                                autoComplete="off" 
                                required
                                value={formData?.prospect}
                                onChange={handleInputChange}
                            />
                        </FormField>
                        <FormField className="col-span-1">
                            <label>Your email</label>
                            <input 
                                type="email" 
                                name="email" 
                                placeholder="Your Email" 
                                autoComplete="off" 
                                required
                                value={formData?.email}
                                onChange={handleInputChange} 
                            />
                        </FormField>
                        <FormField className="col-span-1">
                            <label>Phone Number</label>
                            <input 
                                type="text" 
                                name="phone" 
                                placeholder="+1 1234" 
                                autoComplete="off" 
                                required
                                value={formData?.phone}
                                onChange={handleInputChange}
                            />
                        </FormField>
                        <FormField className="col-span-1">
                            <label>Service</label>
                            <select name="service">
                                <option value="">Select service</option>
                            </select>
                        </FormField>
                        <FormField className="col-span-1">
                            <label>Company</label>
                            <input 
                                type="text" 
                                name="company" 
                                placeholder="Company Name" 
                                autoComplete="off" 
                                required
                                value={formData?.company} 
                                onChange={handleInputChange}
                            />
                        </FormField>
                        <FormField className="col-span-1 md:col-span-2">
                            {/* <label>Descrie Your Project</label> */}
                            <input 
                                type="text" 
                                name="projectDetail" 
                                placeholder="Describe your project" 
                                autoComplete="off"
                                value={formData?.projectDetail}
                                onChange={handleInputChange}
                            />
                        </FormField>
                        <CheckField className="col-span-1">
                            <input 
                                name="policyCheck"
                                type="checkbox"
                                required
                                value={formData?.policyCheck}
                                onChange={handleCheckChange}
                                // checked={formData.policyCheck ? formData?.policyCheck : null} 
                            /> 
                            I accept your 
                            <Link to="#">Privacy Policy</Link>
                        </CheckField>
                        <CheckField className="col-span-1">
                            <input
                            name="shareNDA" 
                                type="checkbox"
                                value={formData?.shareNDA}
                                onChange={handleCheckChange}
                            />
                            Send me NDA
                        </CheckField>
                        {/* <FileField className="col-span-1 md:col-span-2">
                            <input type="file" name="file" placeholder="Attach File"></input>
                            <FileInstructions>
                                <FileFieldSize>Attach File (Upto 10MB)</FileFieldSize>
                                <FileFormats>Allowed extensions: .pdf, .docx, .ppt/x, .xls/x, .txt</FileFormats>
                            </FileInstructions>
                        </FileField> */}
                    </ContactForm>
                    <ButtonContainer>
                        <button type="submit">Submit</button>
                    </ButtonContainer>
                    {
                        qryMsg && qryStatus && (
                            <p className="text-sm text-amber-400 pt-2 italic">{qryMsg}</p>
                        )
                    }
                </form>
            </FormContainer>
            <ContactQuote>
                <p>You have a <span>vision</span>.</p>
                <p>We have a way to get you there.</p>
                <SubQuote>
                    <p>Global Leader in new age digital product development</p>
                </SubQuote>
            </ContactQuote>
        </ContactUsContainer>
    )
}

export default ContactUs