import React, { useEffect, useState } from "react";
import styled from "styled-components";
import tw from "twin.macro";
import Header from "./Header";
import img1 from "../assets/vr.png"

const CarouselContainer = styled.div`
    ${tw`
        w-full
        h-[100vh]
        animate-gradient
    `}
    background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
	background-size: 400% 400%;
`
const SliderContainer = styled.div`
    ${tw`
        w-full
        h-auto
        absolute
        // bg-red-100
        top-[30%]
        lg:px-[120px]
        md:px-[40px]
        px-[30px]
    `}
    div{
        ${tw`
            inline-block
        `}
    }
`

const SliderItem = styled.div`
    ${tw`
        // bg-green-100
        flex
        flex-col
    `}
`

const Line1 = styled.div`
    ${tw`
        md:text-3xl
        text-xl
        font-Raleway
        text-amber-200
        font-medium
        w-full
    `}
`

const Line2 = styled.div`
    ${tw`
        md:text-5xl
        text-3xl
        font-Raleway
        text-white
        font-bold
        mt-5
        w-full
        flex
        justify-between
        items-center
    `}
`

const Line3 = styled.div`
    ${tw`
        md:text-xl
        text-lg
        font-Raleway
        text-white
        mt-5
        lg:w-[50%]
        font-medium
        tracking-wide
        w-full
    `}
`

const ContactButtonContainer = styled.div`
    ${tw`
        mt-7
        w-full
        items-center
        text-center
        md:text-left
        justify-center
        md:justify-start
    `}
    a{
        ${tw`
            // bg-amber-600
            bg-violet-700
            text-white
            py-3
            md:px-6
            px-10
            rounded-lg
            font-medium
            font-Raleway
            uppercase
            // hover:bg-violet-700
            hover:text-white
            w-full
        `}
    }
`

const ExploreButton = styled.div`
    ${tw`
        absolute
        lg:mt-[72vh]
        md:mt-[70vh]
        w-full
        md:flex
        hidden
        items-center
        text-center
        justify-center
    `}
    a{
        ${tw`
            flex
            items-center
            text-center
            justify-between
            gap-3
        `}
        div{
            ${tw`
                h-[75px]
                w-[46px]
                rounded-full
                border-2
                border-dashed
                border-violet-200
                // hover:bg-violet-900
            `}
            ::before{
                ${tw`
                    absolute
                    content-[""]
                    h-[0.8em]
                    w-[0.8em]
                    bg-white
                    rounded-full
                    -ml-[6px]
                    mt-[10px]
                    mb-[10px]
                    animate-move-down
                `}
            }
            ::after{
                ${tw`
                    content-["SCROLL DOWN"]
                    absolute
                    block
                    text-xs
                    bottom-0
                    text-white
                    -mb-[20px]
                    -ml-5
                `}
            }
            
        }
    }
`


const Carousel = () => {
    const words = [
        {
            id:0,
            line1:'Scaling globally with',
            line2:'Product and Data Engineering',
            line3: 'We provide custom data-driven solution, evolving efficiency with revenue growth and cost reduction.',
            img: img1
        },
        {
            id:1,
            line1:'Start working with world class',
            line2:'AR/VR Product Team Today',
            line3: 'We are a high end boutique development studio that buils AR/VR products for startups and corporations.'
        },
        {
            id:2,
            line1:'Redefining',
            line2:'Mobile Experience',
            line3: 'We put disruption in top gear by helping you create exceptional experiences'
        }
    ]

    const [count, setCount] = useState(0)
    const [currentWord, setCurrentWord] = useState(words[0])
    

    useEffect(()=>{
        setTimeout(() => {
            if(count === (words.length - 1) ){
                setCount(0)
            }else{
                setCount(count + 1)
            }
        }, 4000);
        
    },[count])

    
    useEffect(()=>{
        setCurrentWord(words[count])
    },[count])

    useEffect(()=>{
        console.log("Count:", count)
    },[count])
    

    return(
        <CarouselContainer>
            <Header col={"#fff"}/>
            <SliderContainer>
                {
                    currentWord && (
                        <SliderItem>
                            <Line1>
                                {currentWord?.line1}
                            </Line1>
                            <Line2>
                                {currentWord?.line2}
                            </Line2>
                            <Line3>{currentWord?.line3}</Line3>
                            <ContactButtonContainer>
                                <a href="#contactUs">Let's Connect</a>
                            </ContactButtonContainer>
                        </SliderItem>
                    )
                }
            </SliderContainer>
            <ExploreButton>
                <a href="#about"> 
                    <div>
                        
                    </div>    
                </a>
            </ExploreButton>
            
        </CarouselContainer>
    )
}

export default Carousel