import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { Helmet } from "react-helmet-async";


const PageContainer = styled.div`
    ${tw`
        absolute
        // w-[100vw]
        // h-[100vh]
        // bg-red-100
        flex
        flex-col
        items-center
        text-center
        justify-between
        gap-2
        w-full
        top-[46%]

    `}
    h1{
        ${tw`
            lg:text-[5em]
            md:text-4xl
            text-5xl
            font-bold
            text-violet-800
        `}

    }
    span{
        ${tw`
            text-violet-800
            text-base
            mt-2
        `}
    }

`

const Page404 = () => {
    return(
        <>
            <Helmet>
                <title>Error - 404</title>
                <meta name="description" content="404 - Page not found" ></meta>
            </Helmet>
            {/* <HeaderContainer>
                <Header />
            </HeaderContainer> */}
            <PageContainer>
                <h1>404</h1>
                <span>Page Not Found</span>
            </PageContainer>
        </>
    )
}

export default Page404
