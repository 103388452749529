import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import tw from "twin.macro";

import {FaTwitter, FaFacebookF, FaLinkedinIn, FaEnvelope} from "react-icons/fa"
import {BsInstagram} from "react-icons/bs"
import moment from "moment/moment";

const FooterContainer = styled.section`
    ${tw`
        flex
        w-full
        lg:px-[120px]
        md:px-[120px]
        px-[30px]
        bg-slate-800
        grid
        lg:grid-cols-6
        md:grid-cols-3
        grid-cols-1
        py-3
        text-white
        gap-3
    `}
`
const LogoColumn = styled.div`
    ${tw`
        flex
        flex-col
        col-span-1
        md:col-span-3
        lg:col-span-2
        py-3
        order-4
        lg:order-1
    `}
`

const LogoText = styled.span`
    ${tw`
        font-Sedgwick
        md:text-3xl
        text-2xl
    `}
`

const Copywrite = styled.span`
    ${tw`
        w-full
        text-sm
        text-slate-400
    `}
`
const NavColumn = styled.div`
    ${tw`
        flex
        flex-col
        col-span-1
        w-full
        order-1
    `}
    ul{
        ${tw`
            w-full
            mt-2
            pl-1
        `}
    }
    li{
        ${tw`
            text-sm
            text-slate-400
            hover:text-white
        `}
    }
`

const HeaadQuarterColumn = styled.div`
    ${tw`
        flex
        flex-col
        col-span-1
        md:col-span-1
        w-full
        text-sm
        text-slate-400
        order-3
    `}
    ul{
        ${tw`
            w-full
            mt-2
            pl-1
        `}
    }
`

const SupportColumn = styled.div`
    ${tw`
        flex
        flex-col
        col-span-1
        md:col-span-1
        w-full
        text-sm
        text-slate-400
        order-3
    `}
    ul{
        ${tw`
            w-full
            mt-2
            pl-1
        `}
    }
`

const ColumnHeader = styled.h2`
    ${tw`
        font-medium
        text-lg
        text-violet-600
    `}
`

const SocialColumn = styled.div`
    ${tw`
        flex
        flex-col
        col-span-1
        w-full
        order-2
    `}
    ul{
        ${tw`
            w-full
            mt-2
            pl-1
        `}
    }
    li{
        ${tw`
            w-full
            flex
            items-center
            gap-2
            text-sm
            text-slate-400
            hover:text-white
        `}
    }
}
`

const Footer = () => {
    return(
        <FooterContainer>
            <LogoColumn>
                <LogoText>DT</LogoText>
                <Copywrite>
                    © {moment().format("YYYY")} DataTown. All rights reserved
                </Copywrite>
            </LogoColumn>
            <NavColumn>
                <ColumnHeader>Navigation</ColumnHeader>
                <ul>
                    <li>
                        <Link to={"/"}>Home</Link>
                    </li>
                    <li>
                        <Link to={"/products"}>Products</Link>
                    </li>
                    <li>
                        <Link to={"/"}>Services</Link>
                    </li>
                    {/* <li>
                        <Link to={"/"}>About Us</Link>
                        </li>
                    <li>
                        <Link to={"/"}>Careers</Link>
                    </li> */}
                </ul>
            </NavColumn>

            <HeaadQuarterColumn>
                <ColumnHeader>Our Headquarters</ColumnHeader>
                <ul>
                    <li>Lucknow, Uttar Pradesh, IN</li>
                </ul>
            </HeaadQuarterColumn>

            <SocialColumn>
                <ColumnHeader>Social</ColumnHeader>
                <ul>
                    {/* <li>
                        <FaTwitter />
                        <span>Twitter</span>
                    </li> */}
                    <li>
                        <Link to={'https://www.facebook.com/datatownInc'}><FaFacebookF /></Link>
                        <span>Facebook</span>
                    </li>
                    <li>
                        <Link to={'https://www.linkedin.com/company/datatown-analytics/?viewAsMember=true'}><FaLinkedinIn /></Link>
                        <span>LinkedIn</span>
                    </li>
                    {/* <li>
                        <BsInstagram />
                        <span>Instagram</span>
                    </li> */}
                </ul>
            </SocialColumn>
            <SupportColumn>
                <ColumnHeader>Contact Us</ColumnHeader>
                <ul>
                    <li>
                        {/* <FaEnvelope /> */}
                        <span>info@datatown.in</span>
                    </li>
                </ul>
            </SupportColumn>
            
        </FooterContainer>
    )
}

export default Footer