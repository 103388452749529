import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import styled from "styled-components";
import tw from "twin.macro";

import {MdMenu} from "react-icons/md"
import BMenu from "./BurgerMenu";

const HeaderContainer = styled.div`
    ${tw`
        lg:px-[120px]
        md:px-[40px]
        px-5
        bg-transparent
        flex
        items-center
        justify-between
        py-5
       
    `}
`

const LogoTitle = styled.div`
    ${tw`
        font-Sedgwick
        lg:text-5xl
        text-4xl
        // text-white
        mt-2
    `}
`

const NavigationContainer = styled.div`
    ${tw`
        // flex
        gap-8
        uppercase
        // text-white
        hidden
        lg:flex
    `}
`
const BurgerMenu = styled.div`
    ${tw`
        flex
        text-violet-600
        lg:hidden
    `}
`

const Header = (props) => {
    const colr = props.col
    const [menuOpen, setMenuOpen] = useState(null)
    
    const handleSideBar = (e) => {
        if(menuOpen ===  null || menuOpen === false){
            setMenuOpen(true)
        }else{
            setMenuOpen(false)
        }
    }
    
    return(
        <>
            <HeaderContainer style={{color:`${colr}`}}>
                <LogoTitle>
                    <Link to={"/"}>DT</Link>
                </LogoTitle>
                <NavigationContainer>
                    <NavLink to={"/"} style={({ isActive }) => ({borderBottom: isActive ? `5px solid ${colr}` : null})}>Home  </NavLink>
                    <NavLink to={"/products"} style={({ isActive }) => ({borderBottom: isActive ? `5px solid ${colr}` : null})}>Products</NavLink>
                    <NavLink to={"/services"} style={({ isActive }) => ({borderBottom: isActive ? `5px solid ${colr}` : null})}>Services</NavLink>
                    {/* <NavLink to={"/about"} style={({ isActive }) => ({borderBottom: isActive ? '2px solid #6d28d9' : null})}>About Us</NavLink> */}
                    {/* <NavLink to={"/"}>Blog</NavLink> */}
                    {/* <NavLink to={"/careers"} style={({ isActive }) => ({borderBottom: isActive ? '2px solid white' : null})}>Career</NavLink> */}
                </NavigationContainer>
                <BurgerMenu style={{color: `${colr}`}}>
                    <MdMenu size={30} onClick={handleSideBar}/>
                </BurgerMenu>    
            </HeaderContainer>
            <>
                {
                    menuOpen && (
                        <BMenu clickClose={handleSideBar} /> 
                    )
                }
            </>
        </>
    )
}

export default Header