import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { hydrate } from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';

const APP = (
  <React.StrictMode>
    <HelmetProvider>
      <App />
    </HelmetProvider>
  </React.StrictMode>
)

const ROOT = document.getElementById('root');
const root = ReactDOM.createRoot(document.getElementById('root'));

if(ROOT.hasChildNodes()){
  hydrate(APP, ROOT)
}else{
  root.render(APP)  
}