import React, { useState } from "react";
import styled from "styled-components";
import tw from "twin.macro";
import {MdClose} from "react-icons/md"
import { Link, NavLink } from "react-router-dom";

const MenuContainer = styled.div`
    ${tw`
        fixed
        bg-white
        md:w-[30%]
        w-[90%]
        md:ml-[70%]
        ml-[10%]
        lg:hidden
        flex
        flex-col
        -mt-[90px]
        h-[100vh]
        py-5
        px-3
        drop-shadow
        z-[99]
    `}
`

const CrossContainer = styled.div`
    ${tw`
        flex
    `}
`

const NavigationContainer = styled.div`
    ${tw`
        flex
        flex-col
        gap-2
        uppercase
        lg:hidden
        mt-10
    `}
    a{
        ${tw`
            text-violet-600
            w-full
        `}
    }
`

const BMenu = (props) => {
    

    return(
        <MenuContainer>
            <CrossContainer>
                <MdClose size={20} onClick={props.clickClose}/>
            </CrossContainer>
            <NavigationContainer>
                <Link to={"/"} onClick={props.clickClose}>Home  </Link>
                <Link to={"/products"} onClick={props.clickClose}>Products</Link>
                <Link to={"/services"} onClick={props.clickClose}>Services</Link>
                {/* <NavLink to={"/about"} style={({ isActive }) => ({borderBottom: isActive ? '2px solid #6d28d9' : null})}>About Us</NavLink> */}
                {/* <NavLink to={"/"}>Blog</NavLink> */}
                {/* <NavLink to={"/careers"} style={({ isActive }) => ({borderBottom: isActive ? '2px solid white' : null})}>Career</NavLink> */}
            </NavigationContainer>
        </MenuContainer>
    )
}

export default BMenu