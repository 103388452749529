import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import OfficeBg from "../assets/officeBg.jpg"

import {BsClouds, BsGraphUp, BsGlobe, BsVr} from "react-icons/bs"

const SecvicesContainer = styled.section`
    ${tw`
        bg-gray-200
        w-full
        lg:px-[120px]
        md:px-[120px]
        px-[30px]
        py-2
        pb-8
    `}
`

const ServiceHeader = styled.div`
    ${tw`
        w-full
        p-2
        md:text-2xl
        text-xl
        text-slate-600
        font-medium
        flex
        lg:justify-start
        md:justify-center
        md:mt-5
    `}
`

const ServiceList = styled.div`
    ${tw`
        grid
        lg:grid-cols-4
        md:grid-cols-2
        grid-cols-1
        w-full
        gap-5
        // bg-red-200
    `}
`
const ServiceItem = styled.div`
    ${tw`
        flex
        flex-col
        md:p-3
        p-2
        mt-2
        col-span-1
        // lg:hover:bg-gray-100
        bg-violet-900
        text-white
        rounded-xl
        h-[320px]
    `}
`

const ServiceTitle = styled.div`
    ${tw`
        flex
        flex-col
        md:text-xl
        text-lg
        items-center
        justify-start
        font-Raleway
        text-amber-500
        mt-2
    `}
`

const ServiceDetail = styled.div`
    ${tw`
        flex
        mt-2
        px-3
        md:text-[1em]
        text-[0.95em]
        text-slate-200
    `}
`

const ViewAll = styled.div`
    ${tw`
        flex
        w-full
        lg:items-center
        lg:text-center
        lg:justify-center
        mt-5
    `}
    a{
        ${tw`
            // border-dashed
            // border-violet-600
            border-b
            border-2
            border-violet-600
            px-4
            py-1
            rounded-lg
            text-violet-600
        `}
    }
`

const ServiceSection = () => {
    return(
        <SecvicesContainer id="services">
            <ServiceHeader>
                What We Do
            </ServiceHeader>
            <ServiceList>
                <ServiceItem >
                    <ServiceTitle>
                        <BsClouds size={40} />
                        <span>SaaS Applications</span>
                    </ServiceTitle>
                    <ServiceDetail>
                        We provide Software as a Service (SaaS) to consumers who wish to capture the essence of software accessibility around the globe via any device over the internet.
                    </ServiceDetail>
                </ServiceItem>
                
                <ServiceItem>
                    <ServiceTitle>
                        <BsGraphUp size={25}/>
                        <span>BI and Data Analytics</span>
                    </ServiceTitle>
                    <ServiceDetail>
                    We believe in nurturing data with productive analysis. As it serves as the foundation stone of better progression and structure. Hence effective analysis is a must-have for invaluable data.
                    </ServiceDetail>
                </ServiceItem>
                <ServiceItem>
                    <ServiceTitle>
                        <BsGlobe size={30}/>
                        <span>Web applications</span>
                    </ServiceTitle>
                    <ServiceDetail>
                        We have extensive experience in working with high-load systems, data engineering and solving complex business problems. We help you save money, time, and ultimately improve the efficiency of your enterprise's processes.
                    </ServiceDetail>
                </ServiceItem>

                <ServiceItem>
                    <ServiceTitle>
                        <BsVr size={30}/>
                        <span>AR / VR</span>
                    </ServiceTitle>
                    <ServiceDetail>
                        We are experienced in developing high-end Augmented Reality (AR) & Virtual Reality (VR) solutions. It helps businesses amplify their product/service value proposition through a highly adept team of AR & VR App Development consultants. 
                    </ServiceDetail>
                </ServiceItem>
                {/* Its end-to-end Augmented Reality App Development services & Virtual Reality App Development solutions are geared towards creating a surreal yet immersive experience for customers while helping to differentiate the brand from the competition. */}

            </ServiceList>
            <ViewAll>
                <a href="/services">View All</a>
            </ViewAll>
        </SecvicesContainer>
    )
}


export default ServiceSection